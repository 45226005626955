import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react"
import { EnvContextProvider } from '@/components/contexts/envContext'
import AzureAppInsights from '@/components/appInsights/appInsights.componnet';

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    return (
        <SessionProvider session={session} refetchOnWindowFocus>
            <EnvContextProvider>
                <AzureAppInsights>
                    <Component {...pageProps} />
                </AzureAppInsights>
            </EnvContextProvider>
        </SessionProvider>
    );
}
