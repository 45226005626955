import React, {ReactNode, useEffect} from "react";
import { ApplicationInsights} from "@microsoft/applicationinsights-web";
import {
    ReactPlugin,
    withAITracking,
    AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";

let appInsights:ApplicationInsights|undefined=undefined;
const reactPlugin = new ReactPlugin();

function getAppInsights(){
    return appInsights;
}
const telemetryInitializer = (envelope : any) => {
    envelope.tags['ai.cloud.role'] = 'storefront-frontend';
};

async function constructAppInsight(){
    const response = await fetch("/api/envsettings/instrumentation");
    const data = await response.json();
    console.log("instrumentation key:"+ data.instrumentationKey)

    if (!data.instrumentationKey) {
        console.info("Instrumentation key not available.");
        return;
    }

    const  defaulBrowserHistory = {
        url: "/",
        location: {pathname: ""},
        listen: () => {console.log("initalized browser history")}
    };

    let browserHistory = defaulBrowserHistory;
    if (typeof window !== "undefined") {
        browserHistory = {...browserHistory, ...window.history};
        browserHistory.location.pathname =browserHistory?.url;
    }

    appInsights = new ApplicationInsights({
        config: {
            samplingPercentage: 100,
            instrumentationKey: data.instrumentationKey,
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxPerfTracking: true,
            isBrowserLinkTrackingEnabled: true,
            extensionConfig: {
                [reactPlugin.identifier]: {
                    history: browserHistory
                },
            },
        },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);
}



const AzureAppInsights = ({ children }:{children:ReactNode}) => {
    useEffect(()=>{
        constructAppInsight();
    },[]);
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

function logPageView(name: string, properties?: { [key: string]: any }) {
    getAppInsights()?.trackEvent({ name }, properties);
}

function logEvent(name: string, properties?: { [key: string]: any }) {
    getAppInsights()?.trackEvent({ name }, properties);
}

function logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    getAppInsights()?.trackMetric({ name, average: average }, properties);
}

function logException(exception: Error, severityLevel?: number) {
    getAppInsights()?.trackException({ exception, severityLevel });
}

function logTrace(message: string, properties?: { [key: string]: any }) {
    getAppInsights()?.trackTrace({ message }, properties);
}

function trackUserId(userId: string, accountId: string) {
    if (getAppInsights()?.config.disableTelemetry) {
        return;
    }
    getAppInsights()?.setAuthenticatedUserContext(userId, accountId, true);
}

export default withAITracking(reactPlugin, AzureAppInsights);
export {reactPlugin, trackUserId, logTrace, logException, logMetric, logEvent, logPageView, appInsights}
